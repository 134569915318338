<script setup lang="ts">
const { progress, isLoading } = useLoadingIndicator()
</script>

<template>
  <div
    style="transition: opacity 350ms, transform 600ms ease-in;"
    :style="{ transform: `scaleX(${progress}%)` }"
    :class="[isLoading ? 'opacity-100':'opacity-0']"
    class="h-1 fixed top-0 right-0 left-0 pointer-events-none w-auto bg-gradient-to-r from-peacock-400 from-80% to-transparent z-50 origin-left"
  />
</template>
